<template>
  <div>
    <el-form :model="data">
      <el-form-item label="是否展示二维码">
        <div class="x-b">
          <span></span>
          <el-radio-group size="mini" v-model="data.style.display">
            <el-radio-button :label="true">展示</el-radio-button>
            <el-radio-button :label="false">不展示</el-radio-button>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="选择样式" v-if="data.style.display">
        <div class="x-b">
          <span></span>
          <el-radio-group size="mini" v-model="data.style.styleType">
            <el-radio-button :label="1">样式一</el-radio-button>
            <el-radio-button :label="2">样式二</el-radio-button>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="setTitle">二维码设置</div>
      <el-tabs v-model="data.style.weChatOrOfficialAccountsType" stretch v-if="!data.style.display">
        <el-tab-pane label="添加社群码" name="1" />
        <el-tab-pane label="关注公众号" name="2" />
      </el-tabs>
      <el-form-item v-if="data.style.styleType == 1 && data.style.display">
        <el-checkbox :true-label="true" :false-label='false' v-model="data.style.isWeChat">微信群/个人微信号/公众号</el-checkbox>
      </el-form-item>
      <div
        v-if="(data.style.styleType == 1 && data.style.isWeChat) || data.style.styleType == 2 || !data.style.display">
        <el-form-item label="添加二维码" v-if="data.style.display">
          <div class="x-b">
            <span></span>
            <div>
              <div class="choosedImageItem x-fc" @click="getOpenImg(1)">
                <i v-if="!data.style.weChatUrl" class="el-icon-plus" style="font-size: 32px"></i>
                <img v-else class="thumbImage" :src="data.style.weChatUrl" alt="广告图" />
                <span class="modifyImage">{{`${data.style.weChatUrl ? '更换' : '上传'}图片`}}</span>
                <i v-if="data.style.weChatUrl" class="el-icon-circle-close" @click.stop="delImg(1)"></i>
              </div>
              <div class="fontS12" style="color: #969799">建议尺寸：64*64像素</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="二维码标题" v-if="data.style.styleType == 2 || !data.style.display">
          <div class="x-b">
            <span></span>
            <el-input style="width:200px" maxlength="20" size="mini" placeholder="请输入二维码标题" v-model="data.style.title" />
          </div>
        </el-form-item>
        <el-form-item label="二维码描述">
          <div class="x-b">
            <span></span>
            <el-input style="width:200px" maxlength="20" size="mini" placeholder="请输入二维码描述" v-model="data.style.described" />
          </div>
        </el-form-item>
        <el-form-item label="按钮名称" maxlength="10" v-if="!data.style.display && data.style.weChatOrOfficialAccountsType == 1">
          <div class="x-b">
            <span></span>
            <el-input style="width:200px" size="mini" maxlength="4" placeholder="请输入按钮名称" v-model="data.style.addBtnName" />
          </div>
        </el-form-item>
        <el-form-item label="入口图片" v-if="!data.style.display">
          <div class="x-b" style="margin-bottom:20px">
            <span></span>
            <el-radio-group size="mini" v-model="data.style.entranceType">
              <el-radio-button :label="1">默认</el-radio-button>
              <el-radio-button :label="2">自定义</el-radio-button>
            </el-radio-group>
          </div>
          <div class="x-b" v-if="data.style.entranceType == 2">
            <span></span>
            <div class="y-end">
              <div class="choosedImageItem x-fc" @click="getOpenImg(3)">
                <i v-if="!data.style.entranceUrl" class="el-icon-plus" style="font-size: 32px"></i>
                <img v-else class="thumbImage" :src="data.style.entranceUrl" alt="广告图" />
                <span class="modifyImage">{{`${data.style.entranceUrl ? '更换' : '上传'}图片`}}</span>
                <i v-if="data.style.entranceUrl" class="el-icon-circle-close" @click.stop="delImg(3)"></i>
              </div>
              <div class="fontS12" style="color: #969799">建议尺寸：48*48像素，白底Logo</div>
            </div>
          </div>
        </el-form-item>
      </div>
      <el-form-item v-if="data.style.styleType == 1 && data.style.display">
        <el-checkbox :true-label="true" :false-label='false'
          v-model="data.style.isOfficialAccounts">微信群/个人微信号/公众号</el-checkbox>
      </el-form-item>
      <div v-if="(data.style.styleType == 1 && data.style.isOfficialAccounts) && data.style.display">
        <el-form-item label="添加二维码">
          <div class="x-b">
            <span></span>
            <div>
              <div class="choosedImageItem x-fc" @click="getOpenImg(2)">
                <i v-if="!data.style.officialAccountsUrl" class="el-icon-plus" style="font-size: 32px"></i>
                <img v-else class="thumbImage" :src="data.style.officialAccountsUrl" alt="广告图" />
                <span class="modifyImage">{{`${data.style.officialAccountsUrl ? '更换' : '上传'}图片`}}</span>
                <i v-if="data.style.officialAccountsUrl" class="el-icon-circle-close" @click.stop="delImg(2)"></i>
              </div>
              <div class="fontS12" style="color: #969799">建议尺寸：64*64像素</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="二维码描述">
          <div class="x-b">
            <span></span>
            <el-input style="width:200px" size="mini" placeholder="请输入二维码描述" maxlength="20"
              v-model="data.style.describedOfficialAccounts" />
          </div>
        </el-form-item>
      </div>
      <el-form-item v-if="!data.style.display && data.style.weChatOrOfficialAccountsType == 2">
        <div class="fontS12" style="color: #969799">
          仅在用户扫码进入微页面时可展示，用户可一键关注公众号，需要在微信后台开启 去配置
          <a href="https://mp.weixin.qq.com/" target="_blank" style="color: #1c75f4">去配置</a>
        </div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="setTitle">样式设置</div>
      <el-form-item label="卡片颜色">
        <div class="x-bc">
          <span>{{ data.style.cardColor }}</span>
          <el-color-picker v-model="data.style.cardColor" @change="templateMsg"></el-color-picker>
        </div>
      </el-form-item>
      <el-form-item label="背景颜色">
        <div class="x-bc">
          <span>{{ data.style.bgColor }}</span>
          <el-color-picker v-model="data.style.bgColor" @change="templateMsg"></el-color-picker>
        </div>
      </el-form-item>
      <el-form-item label="标题颜色" v-if="data.style.styleType == 2">
        <div class="x-bc">
          <span>{{ data.style.titleColor }}</span>
          <el-color-picker v-model="data.style.titleColor" @change="templateMsg"></el-color-picker>
        </div>
      </el-form-item>
      <el-form-item label="描述颜色">
        <div class="x-bc">
          <span>{{ data.style.describedColor }}</span>
          <el-color-picker v-model="data.style.describedColor" @change="templateMsg"></el-color-picker>
        </div>
      </el-form-item>
        <el-form-item label="按钮颜色" v-if="!data.style.display && data.style.weChatOrOfficialAccountsType == 1">
        <div class="x-bc">
          <span>{{ data.style.btnBgColor }}</span>
          <el-color-picker v-model="data.style.btnBgColor" @change="templateMsg"></el-color-picker>
        </div>
      </el-form-item>
      <el-form-item label="圆角样式">
        <div class="x-f">
          <el-slider style="width: 300px" :max="100" @change="templateMsg" v-model="data.style.borderRadius"
            show-input></el-slider>
        </div>
      </el-form-item>
      <el-form-item label="上边距" label-width="68px">
        <div class="x-f">
          <el-slider style="width: 300px" :max="100" v-model="data.style.paddingTop" show-input
            @change="templateMsg"></el-slider>
        </div>
      </el-form-item>
      <el-form-item label="下边距" label-width="68px">
        <div class="x-f">
          <el-slider style="width: 300px" :max="100" v-model="data.style.paddingBottom" show-input
            @change="templateMsg"></el-slider>
        </div>
      </el-form-item>
      <el-form-item label="左右边距">
        <div class="x-f">
          <el-slider style="width: 300px" :max="100" v-model="data.style.paddingAround" show-input
            @change="templateMsg"></el-slider>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Material from '@/views/components/material/index.vue' //素材库
export default {
  name: 'zhangfen',
  components: {
    Material
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      active: 1,
      showDialog: false,
      content: '活码是用户推广个人微信号或粉丝群的二维码，适用于线上线下，吸引用户添加好友或粉丝群进活码是用户推广个人微信号或粉丝群的二维码，适用于线上线下，吸引用户添加好友或粉丝群进'
    }
  },
  methods: {
    delImg(val) {
      if (val == 1) {
        this.$set(this.data.style, 'weChatUrl', '')
      } else if (val == 2) {
        this.$set(this.data.style, 'officialAccountsUrl', '')
      } else if (val == 3) {
        this.$set(this.data.style, 'entranceUrl', '')
      }
      this.$forceUpdate()
      this.templateMsg()
    },
    getOpenImg(val) {
      this.showDialog = true
      this.active = val
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.$set(this.data.style, `${this.active == 1 ? 'weChatUrl' : this.active == 2 ? 'officialAccountsUrl' : 'entranceUrl'}`, this.accessUrlList[0]?.accessUrl)
      this.imgUpdateCancel()
      this.templateMsg()
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    templateMsg() {
      this.$emit('setTemp', this.data)
    },
    addImg() {
      this.$parent.$parent.materialOpen = true
    },
    // 是否默认图片
    raidoChange(val) {
      console.log('默认图片：', val)
      if (val == 1) {
        this.data.style.imgUrl = 'https://img01.yzcdn.cn/public_files/2019/05/09/50ab4e10f415beac6acf155d10f1f2cb.png!middle.webp'
      }
      this.templateMsg()
    },
    // 选择背景颜色
    selectBGcolor(value) {
      this.data.style.bgColor = value
      this.templateMsg()
    },
  }
}
</script>

<style lang='scss' scoped>
.body {
  color: #333333;
}
.addImgBox {
  width: 80px;
  height: 80px;
  border: 1px solid #bababa;
  cursor: pointer;
}
.choosedImageItem {
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e5e5e5;
  text-align: center;
  margin-right: 10px;
  .thumbImage {
    min-height: 80px;
    box-sizing: border-box;
    vertical-align: bottom;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }
  .modifyImage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .el-icon-circle-close {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    font-size: 22px;
  }
}
.setTitle {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}
</style>
