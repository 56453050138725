var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data } },
        [
          _c("el-form-item", { attrs: { label: "是否展示二维码" } }, [
            _c(
              "div",
              { staticClass: "x-b" },
              [
                _c("span"),
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.data.style.display,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "display", $$v)
                      },
                      expression: "data.style.display",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: true } }, [
                      _vm._v("展示"),
                    ]),
                    _c("el-radio-button", { attrs: { label: false } }, [
                      _vm._v("不展示"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.data.style.display
            ? _c("el-form-item", { attrs: { label: "选择样式" } }, [
                _c(
                  "div",
                  { staticClass: "x-b" },
                  [
                    _c("span"),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.data.style.styleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "styleType", $$v)
                          },
                          expression: "data.style.styleType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("样式一"),
                        ]),
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v("样式二"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("el-divider"),
          _c("div", { staticClass: "setTitle" }, [_vm._v("二维码设置")]),
          !_vm.data.style.display
            ? _c(
                "el-tabs",
                {
                  attrs: { stretch: "" },
                  model: {
                    value: _vm.data.style.weChatOrOfficialAccountsType,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data.style,
                        "weChatOrOfficialAccountsType",
                        $$v
                      )
                    },
                    expression: "data.style.weChatOrOfficialAccountsType",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "添加社群码", name: "1" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "关注公众号", name: "2" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.data.style.styleType == 1 && _vm.data.style.display
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": true, "false-label": false },
                      model: {
                        value: _vm.data.style.isWeChat,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "isWeChat", $$v)
                        },
                        expression: "data.style.isWeChat",
                      },
                    },
                    [_vm._v("微信群/个人微信号/公众号")]
                  ),
                ],
                1
              )
            : _vm._e(),
          (_vm.data.style.styleType == 1 && _vm.data.style.isWeChat) ||
          _vm.data.style.styleType == 2 ||
          !_vm.data.style.display
            ? _c(
                "div",
                [
                  _vm.data.style.display
                    ? _c("el-form-item", { attrs: { label: "添加二维码" } }, [
                        _c("div", { staticClass: "x-b" }, [
                          _c("span"),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "choosedImageItem x-fc",
                                on: {
                                  click: function ($event) {
                                    return _vm.getOpenImg(1)
                                  },
                                },
                              },
                              [
                                !_vm.data.style.weChatUrl
                                  ? _c("i", {
                                      staticClass: "el-icon-plus",
                                      staticStyle: { "font-size": "32px" },
                                    })
                                  : _c("img", {
                                      staticClass: "thumbImage",
                                      attrs: {
                                        src: _vm.data.style.weChatUrl,
                                        alt: "广告图",
                                      },
                                    }),
                                _c("span", { staticClass: "modifyImage" }, [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.data.style.weChatUrl
                                        ? "更换"
                                        : "上传") + "图片"
                                    )
                                  ),
                                ]),
                                _vm.data.style.weChatUrl
                                  ? _c("i", {
                                      staticClass: "el-icon-circle-close",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg(1)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "fontS12",
                                staticStyle: { color: "#969799" },
                              },
                              [_vm._v("建议尺寸：64*64像素")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.data.style.styleType == 2 || !_vm.data.style.display
                    ? _c("el-form-item", { attrs: { label: "二维码标题" } }, [
                        _c(
                          "div",
                          { staticClass: "x-b" },
                          [
                            _c("span"),
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                maxlength: "20",
                                size: "mini",
                                placeholder: "请输入二维码标题",
                              },
                              model: {
                                value: _vm.data.style.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.style, "title", $$v)
                                },
                                expression: "data.style.title",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "二维码描述" } }, [
                    _c(
                      "div",
                      { staticClass: "x-b" },
                      [
                        _c("span"),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            maxlength: "20",
                            size: "mini",
                            placeholder: "请输入二维码描述",
                          },
                          model: {
                            value: _vm.data.style.described,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "described", $$v)
                            },
                            expression: "data.style.described",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  !_vm.data.style.display &&
                  _vm.data.style.weChatOrOfficialAccountsType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮名称", maxlength: "10" } },
                        [
                          _c(
                            "div",
                            { staticClass: "x-b" },
                            [
                              _c("span"),
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  size: "mini",
                                  maxlength: "4",
                                  placeholder: "请输入按钮名称",
                                },
                                model: {
                                  value: _vm.data.style.addBtnName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.style, "addBtnName", $$v)
                                  },
                                  expression: "data.style.addBtnName",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.data.style.display
                    ? _c("el-form-item", { attrs: { label: "入口图片" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "x-b",
                            staticStyle: { "margin-bottom": "20px" },
                          },
                          [
                            _c("span"),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.data.style.entranceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data.style,
                                      "entranceType",
                                      $$v
                                    )
                                  },
                                  expression: "data.style.entranceType",
                                },
                              },
                              [
                                _c("el-radio-button", { attrs: { label: 1 } }, [
                                  _vm._v("默认"),
                                ]),
                                _c("el-radio-button", { attrs: { label: 2 } }, [
                                  _vm._v("自定义"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.data.style.entranceType == 2
                          ? _c("div", { staticClass: "x-b" }, [
                              _c("span"),
                              _c("div", { staticClass: "y-end" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "choosedImageItem x-fc",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getOpenImg(3)
                                      },
                                    },
                                  },
                                  [
                                    !_vm.data.style.entranceUrl
                                      ? _c("i", {
                                          staticClass: "el-icon-plus",
                                          staticStyle: { "font-size": "32px" },
                                        })
                                      : _c("img", {
                                          staticClass: "thumbImage",
                                          attrs: {
                                            src: _vm.data.style.entranceUrl,
                                            alt: "广告图",
                                          },
                                        }),
                                    _c("span", { staticClass: "modifyImage" }, [
                                      _vm._v(
                                        _vm._s(
                                          (_vm.data.style.entranceUrl
                                            ? "更换"
                                            : "上传") + "图片"
                                        )
                                      ),
                                    ]),
                                    _vm.data.style.entranceUrl
                                      ? _c("i", {
                                          staticClass: "el-icon-circle-close",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.delImg(3)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "fontS12",
                                    staticStyle: { color: "#969799" },
                                  },
                                  [_vm._v("建议尺寸：48*48像素，白底Logo")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.data.style.styleType == 1 && _vm.data.style.display
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": true, "false-label": false },
                      model: {
                        value: _vm.data.style.isOfficialAccounts,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "isOfficialAccounts", $$v)
                        },
                        expression: "data.style.isOfficialAccounts",
                      },
                    },
                    [_vm._v("微信群/个人微信号/公众号")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.data.style.styleType == 1 &&
          _vm.data.style.isOfficialAccounts &&
          _vm.data.style.display
            ? _c(
                "div",
                [
                  _c("el-form-item", { attrs: { label: "添加二维码" } }, [
                    _c("div", { staticClass: "x-b" }, [
                      _c("span"),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "choosedImageItem x-fc",
                            on: {
                              click: function ($event) {
                                return _vm.getOpenImg(2)
                              },
                            },
                          },
                          [
                            !_vm.data.style.officialAccountsUrl
                              ? _c("i", {
                                  staticClass: "el-icon-plus",
                                  staticStyle: { "font-size": "32px" },
                                })
                              : _c("img", {
                                  staticClass: "thumbImage",
                                  attrs: {
                                    src: _vm.data.style.officialAccountsUrl,
                                    alt: "广告图",
                                  },
                                }),
                            _c("span", { staticClass: "modifyImage" }, [
                              _vm._v(
                                _vm._s(
                                  (_vm.data.style.officialAccountsUrl
                                    ? "更换"
                                    : "上传") + "图片"
                                )
                              ),
                            ]),
                            _vm.data.style.officialAccountsUrl
                              ? _c("i", {
                                  staticClass: "el-icon-circle-close",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(2)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "fontS12",
                            staticStyle: { color: "#969799" },
                          },
                          [_vm._v("建议尺寸：64*64像素")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "二维码描述" } }, [
                    _c(
                      "div",
                      { staticClass: "x-b" },
                      [
                        _c("span"),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            size: "mini",
                            placeholder: "请输入二维码描述",
                            maxlength: "20",
                          },
                          model: {
                            value: _vm.data.style.describedOfficialAccounts,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.data.style,
                                "describedOfficialAccounts",
                                $$v
                              )
                            },
                            expression: "data.style.describedOfficialAccounts",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.data.style.display &&
          _vm.data.style.weChatOrOfficialAccountsType == 2
            ? _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "fontS12", staticStyle: { color: "#969799" } },
                  [
                    _vm._v(
                      " 仅在用户扫码进入微页面时可展示，用户可一键关注公众号，需要在微信后台开启 去配置 "
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#1c75f4" },
                        attrs: {
                          href: "https://mp.weixin.qq.com/",
                          target: "_blank",
                        },
                      },
                      [_vm._v("去配置")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("el-divider"),
          _c("div", { staticClass: "setTitle" }, [_vm._v("样式设置")]),
          _c("el-form-item", { attrs: { label: "卡片颜色" } }, [
            _c(
              "div",
              { staticClass: "x-bc" },
              [
                _c("span", [_vm._v(_vm._s(_vm.data.style.cardColor))]),
                _c("el-color-picker", {
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.cardColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "cardColor", $$v)
                    },
                    expression: "data.style.cardColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { label: "背景颜色" } }, [
            _c(
              "div",
              { staticClass: "x-bc" },
              [
                _c("span", [_vm._v(_vm._s(_vm.data.style.bgColor))]),
                _c("el-color-picker", {
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.bgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "bgColor", $$v)
                    },
                    expression: "data.style.bgColor",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.data.style.styleType == 2
            ? _c("el-form-item", { attrs: { label: "标题颜色" } }, [
                _c(
                  "div",
                  { staticClass: "x-bc" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.data.style.titleColor))]),
                    _c("el-color-picker", {
                      on: { change: _vm.templateMsg },
                      model: {
                        value: _vm.data.style.titleColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "titleColor", $$v)
                        },
                        expression: "data.style.titleColor",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "描述颜色" } }, [
            _c(
              "div",
              { staticClass: "x-bc" },
              [
                _c("span", [_vm._v(_vm._s(_vm.data.style.describedColor))]),
                _c("el-color-picker", {
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.describedColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "describedColor", $$v)
                    },
                    expression: "data.style.describedColor",
                  },
                }),
              ],
              1
            ),
          ]),
          !_vm.data.style.display &&
          _vm.data.style.weChatOrOfficialAccountsType == 1
            ? _c("el-form-item", { attrs: { label: "按钮颜色" } }, [
                _c(
                  "div",
                  { staticClass: "x-bc" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.data.style.btnBgColor))]),
                    _c("el-color-picker", {
                      on: { change: _vm.templateMsg },
                      model: {
                        value: _vm.data.style.btnBgColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.data.style, "btnBgColor", $$v)
                        },
                        expression: "data.style.btnBgColor",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "圆角样式" } }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("el-slider", {
                  staticStyle: { width: "300px" },
                  attrs: { max: 100, "show-input": "" },
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.borderRadius,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "borderRadius", $$v)
                    },
                    expression: "data.style.borderRadius",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "上边距", "label-width": "68px" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 100, "show-input": "" },
                    on: { change: _vm.templateMsg },
                    model: {
                      value: _vm.data.style.paddingTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "paddingTop", $$v)
                      },
                      expression: "data.style.paddingTop",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下边距", "label-width": "68px" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 100, "show-input": "" },
                    on: { change: _vm.templateMsg },
                    model: {
                      value: _vm.data.style.paddingBottom,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "paddingBottom", $$v)
                      },
                      expression: "data.style.paddingBottom",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("el-form-item", { attrs: { label: "左右边距" } }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("el-slider", {
                  staticStyle: { width: "300px" },
                  attrs: { max: 100, "show-input": "" },
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.paddingAround,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "paddingAround", $$v)
                    },
                    expression: "data.style.paddingAround",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }