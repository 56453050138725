<template>
  <div>
    <div class="marB20">
      <h2>模板选择</h2>
      <el-radio-group v-model="data.style.templateNo" size="mini">
        <el-radio-button label="1">模板一</el-radio-button>
        <el-radio-button label="2">模板二</el-radio-button>
        <el-radio-button label="3">模板三</el-radio-button>
        <el-radio-button label="4">模板四</el-radio-button>
        <el-radio-button label="5">模板五</el-radio-button>
      </el-radio-group>
    </div>
    <div>
      <div class="x-bc">
        <h2>展示设置</h2>
        <i :class="zhanshiIcon" class="pointer" style="color: #000000;font-size: 20px" @click="openZhanShi"></i>
      </div>
      <div v-if="isOpenZhanShi">
        <div v-if="data.style.templateNo == 1">
          <div class="marT10">
            <span class="fontS14">客户信息</span>
            <div class="x-bc marT10">
              <div class="x-f">
                <span class="marR20 lable color000">微信头像</span>
              </div>
              <div class="x-f btnListBox">
                <div class="item x-c" style="color: #979797" v-for="(item, index) in switchList" :key="index"
                  :style="{ fontSize: item.fontSize + 'px' }" @click="switchBtn('isAvatar', index)"
                  :class="{ 'borderColor': avatarItem == index }">
                  {{ item.label }}
                </div>
                <!--                            <el-switch style="zoom: .8" :width="40" v-model="data.style.isAvatar"></el-switch>-->
              </div>
            </div>
            <div class="x-bc marT10">
              <div class="x-f">
                <span class="marR20 lable color000">会员码</span>
              </div>
              <div class="x-f btnListBox">
                <el-switch style="zoom: .8" :width="40" v-model="data.style.isVipQrCode"></el-switch>
              </div>
            </div>
          </div>
          <div class="marT10">
            <span class="fontS14">资产信息</span>
            <div>
              <div class="x-bc marT10">
                <div class="x-f">
                  <span class="marR20 lable color000">资产信息</span>
                </div>
                <div class="x-f btnListBox">
                  <el-select size="mini" style="width: 170px" v-model="data.style.description" placeholder="请选择"
                    @change="descriptionChange">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="x-bc marT10">
                <div class="x-f">
                  <span class="marR20 lable color000">资产描述</span>
                </div>
                <div class="x-f btnListBox">
                  <el-input size="mini" style="width: 170px" :maxlength="12" v-model="data.style.propertyMsg"
                    placeholder="最多12个字以内，可不填"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="data.style.templateNo == 2 || data.style.templateNo == 3 || data.style.templateNo == 4">
          <div class="x-bc marT10 marB10">
            <div class="x-f">
              <span class="color000" style="margin-right:20px">图片布局</span>
              <span class="color000">{{imgTitle || ''}}</span>
            </div>
            <div class="x-f btnListBox">
              <el-switch :width="40" v-model="data.style.isVipQrCode"></el-switch>
            </div>
          </div>
          <div v-if="data.style.isVipQrCode">
            <div>
              <div class="x-w">
                <div class="y-c imgItem cursorP" v-for="(item, index) in typeList" :key="index">
                  <div class="iconBox x-c" :class="[data.style.imgLayoutType == item.type ? 'iconBox2' : '']"
                    @click="clickType(item.type)">
                    <img :src="item.name" :style="iconBox(item.type)" />
                  </div>
                </div>
              </div>
            </div>
            <ZiChanCom :layout='data.style.imgLayoutType' v-model="data.style.selectedImgList"
              @activeChange='activeChange' />
            <div class='moreSettings x-x' v-if="active >= 0 && data.style.selectedImgList.length > 0">
              <div class="choosedImageItem x-fc" @click="getOpenImg()">
                <i v-if="!data.style.selectedImgList[active].imageUrl" class="el-icon-plus" style="font-size: 32px"></i>
                <img v-else class="thumbImage" :src="data.style.selectedImgList[active].imageUrl" alt="" />
                <span class="modifyImage">{{`${data.style.selectedImgList[active].imageUrl ? '更换' : '上传'}图片`}}</span>
                <i v-if="data.style.selectedImgList[active].imageUrl" class="el-icon-circle-close"
                  @click.stop="delImg()"></i>
              </div>
              <div style="margin-right:20px">
                <el-button type="text"
                  @click="openLink = true">{{`${data.style.selectedImgList[active].linkPath ? '修改' : '选择'}链接`}}</el-button>
                <div class="x-f">
                  <span
                    style="margin-right:10px">{{`链接：${data.style.selectedImgList[active].linkPathName || ''}`}}</span>
                  <span v-if="data.style.selectedImgList[active].linkPath" class="el-icon-close operateDel"
                    @click="delLink()"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="data.style.templateNo == 5">
          <div class='moreSettings x-x marB10'>
            <div class="choosedImageItem x-fc" @click="getOpenImg(3, 1)">
              <i v-if="!data.style.topImageUrl" class="el-icon-plus" style="font-size: 32px"></i>
              <img v-else class="thumbImage" :src="data.style.topImageUrl" alt="" />
              <span class="modifyImage">{{`${data.style.topImageUrl ? '更换' : '上传'}图片`}}</span>
              <i v-if="data.style.topImageUrl" class="el-icon-circle-close" @click.stop="delImg(3, 1)"></i>
            </div>
            <div style="margin-right:20px">
              <el-button type="text"
                @click="getOpenLink(3, 1)">{{`${data.style.topLinkPath ? '修改' : '选择'}链接`}}</el-button>
              <div class="x-f">
                <span style="margin-right:10px">{{`链接：${data.style.topLinkPathName || ''}`}}</span>
                <span v-if="data.style.topLinkPath" class="el-icon-close operateDel" @click="delLink(3, 1)"></span>
              </div>
            </div>
          </div>
          <div class='moreSettings x-x marB10'>
            <div class="choosedImageItem x-fc" @click="getOpenImg(3, 2)">
              <i v-if="!data.style.bottomImageUrl" class="el-icon-plus" style="font-size: 32px"></i>
              <img v-else class="thumbImage" :src="data.style.bottomImageUrl" alt="" />
              <span class="modifyImage">{{`${data.style.bottomImageUrl ? '更换' : '上传'}图片`}}</span>
              <i v-if="data.style.bottomImageUrl" class="el-icon-circle-close" @click.stop="delImg(3, 2)"></i>
            </div>
            <div style="margin-right:20px">
              <el-button type="text"
                @click="getOpenLink(3, 2)">{{`${data.style.bottomLinkPath ? '修改' : '选择'}链接`}}</el-button>
              <div class="x-f">
                <span style="margin-right:10px">{{`链接：${data.style.bottomLinkPathName || ''}`}}</span>
                <span v-if="data.style.bottomLinkPath" class="el-icon-close operateDel" @click="delLink(3, 2)"></span>
              </div>
            </div>
          </div>
          <div class="annotateGrey">建议图片宽度500像素，高度460像素</div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div>
      <div class="x-bc">
        <h2>样式设置</h2>
        <i :class="yangShiIcon" class="pointer" style="color: #000000;font-size: 20px" @click="openYangShi"></i>
      </div>
      <div v-if="isOpenYangShi">
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 lable color000">文字颜色</span>
            <span>{{ data.style.textColor }}</span>
          </div>
          <div class="x-f btnListBox">
            <el-color-picker v-model="data.style.textColor" @change="selectTextcolor"></el-color-picker>
          </div>
        </div>
        <div class="x-bc marT10" v-if="data.style.templateNo != 1">
          <div class="x-f">
            <span class="marR20 lable color000">数字颜色</span>
            <span>{{ data.style.realTextColor }}</span>
          </div>
          <div class="x-f btnListBox">
            <el-color-picker v-model="data.style.realTextColor" @change="templateMsg"></el-color-picker>
          </div>
        </div>
        <div class="x-bc marT10" v-if="data.style.templateNo == 1">
          <div class="x-f">
            <span class="marR20 lable color000">卡片背景</span>
          </div>
          <div class="x-f btnListBox">
            <div class="item x-c" v-for="(item, index) in bgStyleList" :key="index"
              @click="setBgStyle(item.style, index)" :class="{ 'borderColor': bgStyleItem == index }">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="marT10">
          <div v-if="data.style.templateNo == 1 && data.style.bgStyle == 'image'">
            <div class="imgBox x-c cursorP" @click="getOpenImg()" style="overflow: hidden;">
              <div class="y-c" v-if="!data.style.bgImage">
                <i class="el-icon-plus" style="font-size: 20px; margin-bottom: 5px"></i>
                <span>添加图片</span>
              </div>
              <div v-else class="choosedImageItem2">
                <el-image :src="data.style.bgImage" width="100%"></el-image>
                <i v-if="data.style.bgImage" class="el-icon-circle-close" @click.stop="delBgImage"></i>
              </div>
            </div>
            <span class="fontS12 marT10" style="color: #bababa;">建议尺寸： 1370x320 像素的图片</span>
          </div>
          <div v-else-if="this.data.style.bgStyle == 'color'">
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 lable color000">背景颜色</span>
                <span>{{ data.style.cardBgColor }}</span>
              </div>
              <div class="x-f btnListBox">
                <el-color-picker v-model="data.style.cardBgColor"></el-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="x-bc marT10" v-if="data.style.templateNo == 1">
          <div class="x-f">
            <span class="marR20 lable color000">卡片样式</span>
          </div>
          <div class="x-f btnListBox">
            <el-select size="mini" style="width: 170px" v-model="data.style.cardStyle" @change="templateMsg"
              placeholder="请选择">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <!-- 上边距 -->
          <div class="x-f marB20">
            <span class="fontS14 lable marR50 color000">上边距</span>
            <el-slider class="slider" v-model="data.marT" :min="0" :max="30" />
          </div>
          <!-- 下边距 -->
          <div class="x-f marB20">
            <span class="fontS14 lable marR50 color000">下边距</span>
            <el-slider class="slider" v-model="data.marB" :min="0" :max="30" />
          </div>
          <!-- 左边距 -->
          <div class="x-f marB20">
            <span class="fontS14 lable marR50 color000">左边距</span>
            <el-slider class="slider" v-model="data.marL" :min="0" :max="30" />
          </div>
          <!-- 右边距 -->
          <div class="x-f marB20">
            <span class="fontS14 lable marR50 color000">右边距</span>
            <el-slider class="slider" v-model="data.marR" :min="0" :max="30" />
          </div>
          <!-- 圆角 -->
          <div class="x-f marB20">
            <span class="fontS14 lable radiuDistance color000">圆角</span>
            <el-slider class="slider" v-model="data.borRadius" :min="0" :max="30" />
          </div>
        </div>
      </div>
    </div>
    <!-- 图片选择弹窗 -->
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <SelectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import SelectLink from '@/views/components/selectLink/index.vue' //链接库
import ZiChanCom from "@/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/ziChanCom.vue";
import Material from '@/views/components/material/index.vue' //素材库
export default {
  name: 'zichan',
  components: { Material, ZiChanCom, SelectLink },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      topLinkType: 1,
      topUrlType: 1,
      openLink: false,
      active: 0,
      typeList: [
        {
          name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-12/fea762dd8d474504b3d27e8c6bfa0b75.png', title: '一行一个', type: 1
        },
        {
          name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/4881d719d8a5462d9818df4ced592c7d.png', title: '一行两个', type: 2
        },
        {
          name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/f6af8c2eb3e64548894dbff6eba3df88.png', title: '一行三个', type: 3
        },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/a7fd5c5dd1fe4603bd1baab16b98db22.png', title: '一左两右', type: 4 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/b69cf7a45c864dcca48c69ad94c96690.png', title: '两左两右', type: 5 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-12/f9bc2b8528ae41589bef29c053d57685.png', title: '两行五个', type: 6 },
      ],
      showDialog: false,
      zhanshiIcon: 'el-icon-arrow-up',
      isOpenZhanShi: true,
      yangShiIcon: 'el-icon-arrow-up',
      isOpenYangShi: true,
      switchList: [
        {
          label: '显示',
        },
        {
          label: '隐藏'
        }
      ],
      options: [
        { label: '积分', value: 1, open: true, num: 0 },
        { label: '优惠券', value: 2, open: true, num: 0 },
        { label: '余额', value: 3, open: true, num: 0 },
        // { label: '集点卡', value: 4, open: false, num: 0 },
        // { label: '权益卡', value: 5, open: false, num: 0 }
      ],
      options2: [
        { label: '无边白底', value: 1 },
        { label: '卡片投影', value: 2 },
        { label: '描边白底', value: 3 },
        { label: '无边透明底', value: 4 }
      ],
      bgStyleList: [
        { style: 'image', name: '图片' },
        { style: 'color', name: '颜色' }
      ],
      bgStyleItem: 1,
      avatarItem: 0, // 头像选项标记
      accessUrlList: []
    }
  },
  computed: {
    // 图片布局类型
    imgLayoutType() {
      return this.typeList?.find(x => this.data?.style?.imgLayoutTypee == x?.type)?.title || '';
    },
    iconBox() {
      return (value) => {
        switch (value) {
          case 1:
          case 2:
          case 3:
            return { height: '10px', width: '30px' };
          case 4:
          case 5:
            return { height: '20px', width: '20px' };
          case 6:
            return { height: '20px', width: '25px' };
          default:
            break;
        }
      }
    }
  },
  methods: {
    delBgImage() {
      this.$set(this.data.style, 'bgImage', '')
    },
    getOpenLink(type = 1, line = 1) {
      if (type == 3) {
        this.topLinkType = line;
      }
      this.openLink = true;
    },
    activeChange(val) {
      this.active = val
    },
    clickType(type) {
      this.data.style.imgLayoutType = type
      switch (type) {
        case 1:
          this.data.style.selectedImgList = [
            {
              label: '622*220像素',
            }
          ];
          break;
        case 2:
          this.data.style.selectedImgList = [
            {
              label: '300*220像素',
            },
            {
              label: '300*220像素',
            }
          ];
          break;
        case 3:
          this.data.style.selectedImgList = [
            {
              label: '196*220像素',
            },
            {
              label: '196*220像素',
            },
            {
              label: '196*220像素',
            }
          ];
          break;
        case 4:
          this.data.style.selectedImgList = [
            {
              label: '300*456像素',
            },
            {
              label: '300*220像素',
            },
            {
              label: '300*220像素',
            }
          ];
          break;
        case 5:
          this.data.style.selectedImgList = [
            {
              label: '300*220像素',
            },
            {
              label: '300*220像素',
            },
            {
              label: '300*220像素',
            },
            {
              label: '300*220像素',
            }
          ];
          break;
        case 6:
          this.data.style.selectedImgList = [
            {
              label: '300*220像素',
            },
            {
              label: '300*220像素',
            },
            {
              label: '196*220像素',
            },
            {
              label: '196*220像素',
            },
            {
              label: '196*220像素',
            }
          ];
          break;
        case 7:
          this.data.style.selectedImgList = [
            {
              label: '750x375像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '375x375像素',
            }
          ];
          break
        case 8:
          this.data.style.selectedImgList = [
            {
              label: '375x750像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '188x375像素',
            },
            {
              label: '188x375像素',
            }
          ];
          break;
        default:
          break;
      }
      this.templateMsg()
    },
    delLink(type = 1, line = 1) {
      if (type == 3) {
        if (line == 1) {
          this.$set(this.data.style, 'topLinkPath', null)
          this.$set(this.data.style, 'topLinkPathName', null)
        } else {
          this.$set(this.data.style, 'bottomLinkPath', null)
          this.$set(this.data.style, 'bottomLinkPathName', null)
        }
      } else {
        this.$set(this.data.style.selectedImgList[this.active], 'linkPath', '')
        this.$set(this.data.style.selectedImgList[this.active], 'linkPathName', '')
      }
      this.templateMsg()
    },
    delImg(type = 1, line = 1) {
      if (type == 3) {
        if (line == 1) {
          this.$set(this.data.style, 'topImageUrl', '')
        } else {
          this.$set(this.data.style, 'bottomImageUrl', '')
        }
      } else {
        this.$set(this.data.style.selectedImgList[this.active], 'imageUrl', '')
      }
      this.templateMsg()
    },
    getOpenImg(type = 1, line = 1) {
      if (type == 3) {
        this.topUrlType = line
      }
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      if (this.data.style.templateNo == 2 || this.data.style.templateNo == 3 || this.data.style.templateNo == 4) {
        this.$set(this.data.style.selectedImgList[this.active], 'imageUrl', this.accessUrlList[0]?.accessUrl)
      } else if (this.data.style.templateNo == 5) {
        if (this.topUrlType == 1) {
          this.$set(this.data.style, 'topImageUrl', this.accessUrlList[0].accessUrl)
        } else {
          this.$set(this.data.style, 'bottomImageUrl', this.accessUrlList[0].accessUrl)
        }
      } else {
        this.$set(this.data.style, 'bgImage', this.accessUrlList[0].accessUrl)
      }
      this.imgUpdateCancel()
      this.templateMsg()
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    // 选择链接
    selectLinkObj(value) {
      if (this.data.style.templateNo == 5) {
        if (this.topLinkType == 1) {
          this.$set(this.data.style, 'topLinkPath', value.url)
          this.$set(this.data.style, 'topLinkPathName', value.name)
        } else {
          this.$set(this.data.style, 'bottomLinkPath', value.url)
          this.$set(this.data.style, 'bottomLinkPathName', value.name)
        }
      } else {
        this.$set(this.data.style.selectedImgList[this.active], 'linkPath', value.url)
        this.$set(this.data.style.selectedImgList[this.active], 'linkPathName', value.name)
      }
    },
    openZhanShi() {
      if (this.zhanshiIcon == 'el-icon-arrow-up') {
        this.isOpenZhanShi = false
        this.zhanshiIcon = 'el-icon-arrow-down'
      } else {
        this.isOpenZhanShi = true
        this.zhanshiIcon = 'el-icon-arrow-up'
      }
    },
    openYangShi() {
      if (this.yangShiIcon == 'el-icon-arrow-up') {
        this.isOpenYangShi = false
        this.yangShiIcon = 'el-icon-arrow-down'
      } else {
        this.isOpenYangShi = true
        this.yangShiIcon = 'el-icon-arrow-up'
      }
    },
    templateMsg() {
      this.$emit('setTemp', this.data)
    },
    // 文字颜色
    selectTextcolor(value) {
      if (value != '#FFFFFF') {
        this.data.style.cardStyle = 1
      }
      this.data.style.textColor = value
      this.templateMsg()
    },
    setBgStyle(style, index) {
      this.data.style.bgStyle = style
      this.bgStyleItem = index
      this.templateMsg()
    },
    // 资产信息选择
    descriptionChange(val) {
      console.log('资产信息', val)
      if (val == 1) {
        this.data.style.descriptionText = '积分'
      } else if (val == 2) {
        this.data.style.descriptionText = '优惠券'
      } else if (val == 3) {
        this.data.style.descriptionText = '余额'
      } else if (val == 4) {
        this.data.style.descriptionText = '集点卡'
      } else if (val == 5) {
        this.data.style.descriptionText = '权益卡'
      }
      this.templateMsg()
    },
    // 头像选择切换
    switchBtn(str, index) {
      this.avatarItem = index
      this.data.style[str] = index == 0 ? true : false // 头像是否显示
    },
    // 选择要显示的会员信息
    descriptionArr(val) {
      console.log('会员信息展示选择', val)
      if (this.data.style.descriptionArr.length >= 3) {
        this.$message.info('最多支持开启3个资产')
      } else {
        this.data.style.descriptionArr.push(val)
      }
      this.data.style.descriptionArr.forEach((item, index) => {
        if (item.label == val.label) {
          item.open = !item.open
        }
        if (!item.open) {
          this.data.style.descriptionArr.splice(index, 1)
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.moreSettings {
  padding: 10px 0 10px 15px;
  margin-top: 20px;
  background-color: #f7f8fa;
  .choosedImageItem {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #e5e5e5;
    text-align: center;
    margin-right: 10px;
    .thumbImage {
      min-height: 80px;
      box-sizing: border-box;
      vertical-align: bottom;
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
    }
    .modifyImage {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
    .el-icon-circle-close {
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      font-size: 22px;
    }
  }
}

.lable {
  margin-right: 16px;
  font-size: 12px;
  color: #bbbbbb;
  line-height: 18px;
  white-space: nowrap;
}

.btnListBox {
  border-radius: 5px;

  .item {
    height: 30px;
    border: 1px solid #ebedf0;
    padding: 0 20px;
    cursor: pointer;
  }

  .borderColor {
    border: 1px solid #1c75f4;
  }
}

.imgBox {
  width: 100px;
  height: 100px;
  border: 1px solid #e5e5e5;
  color: #155bd4;
  font-size: 12px;
  background: #f2f4f6;

  .img {
    width: 100%;
    height: 100%;
  }
}

.descriptionArr {
  width: 100%;
  color: #333333;

  .item {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #dedede;
  }
}

//拖动
.slider {
  width: calc(100% - 106px);
}

//圆角
.radiuDistance {
  margin-right: 30px;
}
.color000 {
  color: #000 !important;
}
.imgItem {
  color: #989898;
  margin-right: 20px;
  margin-bottom: 10px;
  .iconBox {
    width: 50px;
    height: 50px;
    border: 1px solid #e5e5e5;
    background: #f5f5f5;
  }

  .iconBox2 {
    border: 1px solid #1458cc;
    background: #d5e2f3;
  }

  span {
    width: 50px;
  }
}
.choosedImageItem2 {
  position: relative;
  width: 100px;
  height: 100px;
  .el-icon-circle-close {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    font-size: 22px;
  }
}
</style>
