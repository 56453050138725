<template>
  <!-- 右边控制商品样式组件 -->
  <div class="goods">
    <el-form :model="data">
      <div class="x-bc marB10">
        <span class="marR20 label">添加活动</span>
        <el-radio-group @change="typeChange" v-model="data.style.type">
          <el-radio :label="1">优惠券</el-radio>
          <el-radio :label="2">券包</el-radio>
          <el-radio :label="3">次卡</el-radio>
        </el-radio-group>
      </div>
      <div class="couponBox">
        <div class="selectGoods x-f" @click="handOpen">
          <div class="select-btn">
            {{data.style.type == 1 ? '添加优惠券' : '添加券包'}}
          </div>
        </div>
      </div>
      <div class="control-group">
        <div class="control-group__header marB10">优惠券列表</div>
        <el-table border
          :data="data.style.type == 1 ? data.couponList : data.style.type == 2 ? data.couponPackageList : data.timesCardList"
          height="300px" style="width: 100%" ref="table">
          <template v-if="data.style.type == 1">
            <el-table-column align="center" prop="couponCaseNo" label="优惠券编号" v-if="data.style.type == 1" />
            <el-table-column align="center" prop="couponCaseName" label="优惠券名称" />
            <el-table-column align="center" prop="couponDesc" label="内容" />
            <el-table-column align="center" prop="salePrice" label="售价" width="60" />
          </template>
          <template v-else-if="data.style.type == 2">
            <el-table-column align="center" prop="packageName" label="券包名称" />
            <el-table-column align="center" prop="packagePrice" label="券包售价" />
          </template>
          <template v-else-if="data.style.type == 3">
            <el-table-column align="center" prop="timesCardNo" label="次卡编码" />
            <el-table-column align="center" prop="timesCardName" label="次卡名称" />
            <el-table-column align="center" prop="salePrice" label="次卡售价" />
          </template>
          <el-table-column align="center" prop="date" label="操作" width="60">
            <template slot-scope="scope">
              <span @click="handleClick(scope.row, scope.$index)" style="color: red;">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分割线 -->
      <div v-if="data.style.type == 2">
        <el-divider></el-divider>
        <div class="x-bc marB10">
          <span class="marR20 label">标题文字</span>
          <el-input maxlength="20" style="width:200px" v-model="data.style.title" placeholder="请输入标题文字" />
        </div>
        <div class="x-bc marB10">
          <span class="marR20 label">活动名称</span>
          <el-input maxlength="20" style="width:200px" v-model="data.style.mainTitle" placeholder="请输入活动名称" />
        </div>
      </div>
      <el-divider></el-divider>
      <div class="control-group">
        <div class="x-f">
          <div class="control-group__header fontColorAF">列表样式</div>
          <div class="marL10 ">{{ listModeText }}</div>
        </div>
        <div class="control-group__content">
          <div class="list-radio x-f">
            <div class="item-radio cursorP x-c" v-for="(item, index) in listStyle" :key="item.id"
              :class="{ active: data.style.curListStyleIndex === item.id }" @click="clickListStyle(item, index)">
              <div class="icon x-c">
                <iconpark-icon :name="item.icon" size="18px"></iconpark-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <!-- 上边距 -->
        <div class="x-f marB20">
          <span class="fontS14 lable marR50 color000">上边距</span>
          <el-slider class="slider" v-model="data.style.marT" :min="0" :max="30" />
        </div>
        <!-- 下边距 -->
        <div class="x-f marB20">
          <span class="fontS14 lable marR50 color000">下边距</span>
          <el-slider class="slider" v-model="data.style.marB" :min="0" :max="30" />
        </div>
        <!-- 左边距 -->
        <div class="x-f marB20">
          <span class="fontS14 lable marR50 color000">左边距</span>
          <el-slider class="slider" v-model="data.style.marL" :min="0" :max="30" />
        </div>
        <!-- 右边距 -->
        <div class="x-f marB20">
          <span class="fontS14 lable marR50 color000">右边距</span>
          <el-slider class="slider" v-model="data.style.marR" :min="0" :max="30" />
        </div>
        <!-- 圆角 -->
        <div class="x-f marB20">
          <span class="fontS14 lable radiuDistance color000">圆角</span>
          <el-slider class="slider" v-model="data.style.borRadius" :min="0" :max="30" />
        </div>
      </div>
    </el-form>
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import {
  packageDetailAPI
} from '@/api/marketing/coupon/package' //优惠券包
import Dialog from "@/components/Dialog";
import { deepCopy } from '@/utils';
/**
 * 排列样式：
 *
 * 垂直排列
 * 四宫格排列
 * 横向排列，自动换行
 * 横向滚动
 */
export default {
  name: 'payCoupon',
  components: { Dialog },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "选择券包",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "couponPackage" }),
      },
      //列表样式数组
      listStyle: [
        { id: 0, icon: 'datuxianshi', text: '大图模式' },
        { id: 3, icon: 'hengxianggundong', text: '横向滑动' },
      ],
      listModeText: '大图模式', // 列表样式文本
    }
  },
  watch: {
    //侦听购物车样式 是否显示自定位购物车按钮文字
    'data.curShoppingCardIndex': {
      handler(val) {
        this.isDiyBtnText =
          val === 4 || val === 5 || val === 6 || val === 7 ? true : false
      },
      immediate: true
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  methods: {
    typeChange() {
      this.data.couponList = []
      this.data.couponPackageList = []
      this.data.timesCardList = []
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    handOpen() {
      if (this.data.style.type == 1) {
        this.dialogOptions.title = '选择优惠券',
          this.dialogOptions.formData = this.$dialog({
            key: "coupon",
            option: {
              table: {
                ...this.$dialog({ key: "coupon" }).table,
              },
            },
          });
        this.dialogOptions.formData.table.echoChecked =
          this.data.couponList?.map(
            (item) => item.couponCaseId
          );
        this.dialogOptions.formData.table.pagination = {
          ...this.dialogOptions.formData.table.pagination,
          couponCaseTypes: [0, 1, 2],
          isEnableSale: 1,
          isStop: false,
          isInvalid: 1,
          pageNum: 1,
          pageSize: 100,
        };
        this.dialogOptions.show = true;
      } else if (this.data.style.type == 2) {
        this.dialogOptions.title = '选择券包',
          this.dialogOptions.formData = this.$dialog({
            key: "couponPackage",
            option: {
              table: {
                ...this.$dialog({ key: "couponPackage" }).table,
                radioSelect: true,
                mutiSelect: false,
              },
            },
          });
        this.dialogOptions.formData.table.pagination = {
          ...this.dialogOptions.formData.table.pagination,
          isStop: 1,
          pageNum: 1,
          pageSize: 100,
        };
        this.dialogOptions.show = true;
      } else {
        this.dialogOptions.title = '选择次卡',
          this.dialogOptions.formData = this.$dialog({
            key: "timesCardList",
            option: {
              table: {
                ...this.$dialog({ key: "timesCardList" }).table,
              },
            },
          });
        this.dialogOptions.formData.table.echoChecked =
          this.data.timesCardList?.map(
            (item) => item.timesCardId
          );
        this.dialogOptions.formData.table.pagination = {
          ...this.dialogOptions.formData.table.pagination,
          pageNum: 1,
          pageSize: 100,
        };
        this.dialogOptions.show = true;
      }
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          if (this.data.style.type == 1) {
            this.data.couponList = this.dialogOptions.formData.table.check;
          } else
            if (this.data.style.type == 2) {
              this.data.couponPackageList = this.dialogOptions.formData.table.check;
              const res = await packageDetailAPI(this.data.couponPackageList[0].packageId)
              this.data.couponList = res.data.marketingCouponPackageDetails
            } else if (this.data.style.type == 3) {
              this.data.timesCardList = this.dialogOptions.formData.table.check;
            }
          this.templateMsg();
          console.log('templateMsg', deepCopy(this.data));
          break;
        default:
          break;
      }
    },
    handleClick(row, index) {
      if (this.data.style.type == 1) {
        this.data.couponList.splice(index, 1);
      } else if (this.data.style.type == 2) {
        this.data.couponPackageList = [];
        this.data.couponList = [];
        this.data.timesCardList = [];
      } else if (this.data.style.type == 3) {
        this.data.timesCardList.splice(index, 1);
      }
    },
    //传送控制中间商品样式数据
    templateMsg() {
      this.$emit('setTemp', this.data)
    },
    //点击列表样式事件
    clickListStyle(item, index) {
      this.$set(this.data.style, 'curListStyleIndex', item.id)
      this.listModeText = item.text
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
.couponBox {
  width: 100%;
  background-color: #f6f7f9;
  padding: 10px;
}

//标题样式
.module-title {
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
  color: #333;
}

//选择商品按钮样式
.select-btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: rgba(21, 91, 212, 0.03);
  border: 1px dashed rgba(21, 91, 212, 0.3);
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
  color: #155bd4;
  border-collapse: collapse;

  .select-num {
    color: #999;
    font-size: 12px;
  }
}

//分割线样式
.el-divider {
  margin: 10px 0;
}

//每一组控制
.control-group {
  margin-bottom: 15px;

  .control-group__header {
    font-size: 14px;
  }
}

//列表样式
.list-radio {
  margin-top: 10px;

  .colorLumpBox {
    padding: 3px;
    border: 1px solid #d2d2d2;
    border-radius: 50px;
    margin-right: 10px;

    .colorLump {
      width: 15px;
      height: 15px;
      border-radius: 50px;
    }
    //焦点
    &.active {
      border-color: #155bd4;
    }
  }

  .text {
    color: #999;
  }

  .item-radio {
    text-align: center;

    .icon {
      //width: 30px;
      height: 32px;
      border: 1px solid #e0e0e0;
      padding: 0 16px;
      //margin-bottom: 8px;
    }

    //焦点s
    &.active {
      color: #155bd4;
    }

    &.active .icon {
      background: #edf4ff;
      border-color: #155bd4;
    }

    &.active .text {
      color: #155bd4;
    }
  }
}
.lable {
  margin-right: 16px;
  font-size: 12px;
  color: #bbbbbb;
  line-height: 18px;
  white-space: nowrap;
}
.slider {
  width: calc(100% - 106px);
}
//圆角
.radiuDistance {
  margin-right: 30px;
}
.color000 {
  color: #000 !important;
}
</style>
